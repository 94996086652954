import React from 'react';
import './Overlay.scss';
import I18n from '../../../../i18n/utils';
import { parseHTML } from '../../../utils';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';

const Overlay = () => {

  const hidePopover = () => {
    document.body.click();
  }

  return (
    <div className="Overlay">
      <OverlayTrigger
        className="popover-container"
        trigger="click"
        key={1}
        placement={'bottom-end'}
        rootCloseEvent="click"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-positioned-center`}>
            <div className="popover-alert">
              <Popover.Content>
                {
                  <>
                    <Row className="header">
                      <Col xs="10">
                        <h2>{I18n.t('CORRECTIVE_ACTION_ALERT_TITLE')}</h2>
                      </Col>
                      <Col xs="2">
                        <img
                          alt="close-icon"
                          src={require('../../../assets/icons/close-circle-outline.svg').default}
                          className="close-icon"
                          onClick={hidePopover}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{parseHTML(I18n.t('CORRECTIVE_ACTION_EXCLUDE'))}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{parseHTML(I18n.t('CORRECTIVE_ACTION_RESTRICT'))}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{parseHTML(I18n.t('CORRECTIVE_ACTION_ERROR'))}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>{parseHTML(I18n.t('CORRECTIVE_ACTION_ACCEPTABLE'))}</p>
                      </Col>
                    </Row>
                  </>
                }
              </Popover.Content>
            </div>
          </Popover>
        }
      >
        <img
          alt=""
          src={require('../../../assets/icons/info-icon.svg').default}
          className="info-icon"
        />
      </OverlayTrigger>
    </div>
  );
};

export { Overlay };