import {
  SET_ACCESS_TOKEN,
  //
  SET_USER_TOKEN,
} from '../actions';

const EMAIL_CLAIMS = [
  process.env.REACT_APP_TMHP_EMAIL_AUDIENCE_OPERATOR_AUDIENCE,
  process.env.REACT_APP_TMHP_EMAIL_AUDIENCE_CONTRACTOR_AUDIENCE,
  process.env.REACT_APP_TMHP_EMAIL_AUDIENCE_STAFF_AUDIENCE
];

export const setUserSession = (accessToken) => {
  return dispatch => {
    // Method to set all User session data
    dispatch(setAccessToken(accessToken));
    dispatch(updateUserPermissions(accessToken));
  }
}

// Update User Token with Permissions
const updateUserPermissions = (accessToken) => {
  return async (dispatch, getState) => {
    const jwt = parseJwt(accessToken);
    const { cfa_aud, cfa_perms } = jwt;
    const locationNumber = await getState().location.selectedLocation;
    let userToken = {};
    userToken['isOperator'] = EMAIL_CLAIMS.indexOf(cfa_aud) > -1;
    userToken['isAdmin'] = cfa_perms.FOOD_SAFETY_WEB_APP && cfa_perms.FOOD_SAFETY_WEB_APP.ADMIN && cfa_perms.FOOD_SAFETY_WEB_APP.ADMIN.indexOf(locationNumber) > -1;
  
    // Update User Token in Redux
    dispatch(setUserToken(userToken));
  }
}

// Method to parse JWT token
const parseJwt = (token) => {
  let json;
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    json = JSON.parse(window.atob(base64));
  } catch (e) {
    console.error('error parsing jwt ' + String(e));
  }
  return json;
}

const setAccessToken = (accessToken) => {
  return dispatch => {
    dispatch({ type: SET_ACCESS_TOKEN, payload: accessToken });
  }
}

const setUserToken = (userToken) => {
  return dispatch => {
    dispatch({ type: SET_USER_TOKEN, payload: userToken });
  }
}