import React from 'react';
import './ModalPopup.scss';
import I18n from '../../../../i18n/utils';
import { Modal } from 'react-bootstrap';
import { DatePicker } from '../DatePicker/DatePicker';

const ModalPopup = ({ onClose, onSubmit, type, submitText }) => {
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
      className="ModalPopup"
    >
      {
        type === 'sms-info-modal' ?
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>{I18n.t('SMS_INFO_MODAL_TITLE')}</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{I18n.t('SMS_INFO_MODAL_TEXT_1')}</p>
              <p>{I18n.t('SMS_INFO_MODAL_TEXT_2')}</p>
              <p>{I18n.t('SMS_INFO_MODAL_TEXT_3')}</p>
              <p>{I18n.t('SMS_INFO_MODAL_TEXT_4')}</p>
            </Modal.Body>
          </>
          :
          <>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h2>{I18n.t('LABOR_REPORT_MODAL_TITLE')}</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DatePicker onSubmit={onSubmit} submitText={submitText}/>
            </Modal.Body>
          </>
      }

    </Modal>
  );
}

export { ModalPopup };
