import React, {Component, createRef} from 'react';
import { connect } from 'react-redux';
import './HistoryPage.scss';
import I18n from '../../../../i18n/utils';
import { parseHTML, formatReverseDate } from '../../../utils';
import { Container, Collapse, Row } from 'react-bootstrap';
import { Card, PrimaryButton, ListDivider, ModalPopup } from '../../../components/common';
import { AccordionList } from '../../../components/common';
import { sendHealthPromptHistoryEmail, getHealthPromptLaborReport, resetHealthPromptLaborReport } from '../../../actions';
import { CSVLink} from 'react-csv';
import '../../../components/common/Buttons/PrimaryButton/PrimaryButton.scss'
import {typeOf} from "uri-js/dist/esnext/util";

const ENV = process.env.REACT_APP_ENV;

class HistoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: true,
      loading: props.loading,
      healthPrompts: null,
      showDatePickerModal: false,
      userToken: props.userToken,
      healthPromptsRaw: [
        {"employee_name": null, "location_number": null, "action": "TBD", "clock_in_time": null}
      ],
      laborReportRaw: [
        {"firstname": null, "lastname": null, "count": null, "hours": null}
      ],
      locationNumber: String,
      laborFileName: String
    };

    this.myref = createRef();
    this.resetHealthPromptLaborReport = this.resetHealthPromptLaborReport.bind(this);
    this.showLaborReportDatePicker = this.showLaborReportDatePicker.bind(this);
    this.closeLaborReportDatePicker = this.closeLaborReportDatePicker.bind(this);
    this.getLaborReport = this.getLaborReport.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);

  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update User Token
    if (props.userToken !== state.userToken) {
      update.userToken = props.userToken;
    }

    // Update Alerts
    if (props.alerts !== state.alerts) {
      update.alerts = props.alerts;
    }

    // Update Health Prompts
    if (props.healthPrompts !== state.healthPrompts) {
      update.healthPrompts = props.healthPrompts;
    }

    //Update Health Prompts Csv
    if(props.healthPromptsRaw !== state.healthPromptsRaw) {
      update.healthPromptsRaw = props.healthPromptsRaw;

      update.healthPromptsRaw.sort(function (a, b) { return b['date'] - a['date'] });
      
      // Remove column no longer necessary after sorting
      for (const record of update.healthPromptsRaw) { delete record['date']; }
    }

    //Update Labor Csv
    if(props.laborReportRaw !== state.laborReportRaw) {
      update.laborReportRaw = props.laborReportRaw;
    }

    //Update Location Number
    if(props.locationNumber !== state.locationNumber) {
      update.locationNumber = props.locationNumber;
    }

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  showLaborReportDatePicker() {
    this.props.resetHealthPromptLaborReport();
    this.setState({ showDatePickerModal: true });
  }

  closeLaborReportDatePicker() {
    this.setState({ showDatePickerModal: false });
  }

  getLaborReport(data) {
    const { startDate, endDate } = data;
    this.props.getHealthPromptLaborReport(startDate, endDate);
  }

  resetHealthPromptLaborReport(data){
    const { startDate, endDate } = data;
    this.setState({laborFileName: formatReverseDate(startDate) + "_" + formatReverseDate(endDate)})
    window.setTimeout(() => {
        this.myref.link.click();
        this.props.resetHealthPromptLaborReport();
        this.setState({showDatePickerModal: false});
      }, 100);
  }

  async componentDidUpdate() {
  }

  toggleInfo() {
    const showInfo = !this.state.showInfo;
    this.setState({ showInfo });
  }

  render() {
    return (
      <Container className="HistoryPage">
        <Card className="Card"
          content={
            <>
              <Row className="row" onClick={this.toggleInfo}>
                <label className="card-label">{`${this.state.showInfo ? 'Less' : 'More'} Info`}</label>
                <img
                  alt=""
                  src={require('../../../assets/icons/down-arrow.svg').default}
                  className={`arrow-icon ${this.state.showInfo ? 'upside-down' : ''}`}
                />
              </Row>
              <Collapse in={this.state.showInfo}>
                <div>
                  <Row>
                    <p>{parseHTML(I18n.t('HISTORY_ALERT_HISTORY_MESSAGE'))}</p>
                  </Row>
                  {
                    typeOf(this.state.laborReportRaw) === "error" &&
                    <Row>{parseHTML(I18n.t('LABOR_ALERT_MESSAGE'))}</Row>
                  }
                  {
                    this.state.userToken &&
                    (this.state.userToken.isOperator ||
                      ENV === 'dev') &&
                    <Row>
                      {
                        this.state.laborReportRaw != null &&
                        typeOf(this.state.laborReportRaw) !== "error" &&
                        <CSVLink
                          id={"laborReport"}
                          data={this.state.laborReportRaw}
                          ref={myref => this.myref = myref}
                          filename={this.state.locationNumber + "-health-prompt-labor-report-" + this.state.laborFileName + '.csv'}
                        >
                        </CSVLink>
                      }

                      {
                        <PrimaryButton
                          text={I18n.t('GET_LABOR_REPORT_BUTTON')}
                          onClick={this.showLaborReportDatePicker}
                        />
                      }
                      {
                        this.state.healthPromptsRaw != null &&
                        <CSVLink
                          data={this.state.healthPromptsRaw}
                          filename={"History-90-Day.csv"}
                          className="DownloadCsv"
                        >
                          <PrimaryButton
                            text={I18n.t('DOWNLOAD_90_DAY_BUTTON')}
                          />
                        </CSVLink>
                      }
                      {
                        this.state.showDatePickerModal &&
                        this.state.laborReportRaw === null &&
                          <ModalPopup
                            type="date-picker"
                            onSubmit={this.getLaborReport}
                            onClose={this.closeLaborReportDatePicker}
                          />
                      }
                      {
                        this.state.showDatePickerModal &&
                        this.state.laborReportRaw !== null &&
                        typeOf(this.state.laborReportRaw) !== "error" &&
                        <ModalPopup
                            type="date-picker"
                            submitText="Download"
                            onSubmit={this.resetHealthPromptLaborReport}
                            onClose={this.closeLaborReportDatePicker}
                          />
                      }
                    </Row>
                  }
                </div>
              </Collapse>
            </>
          }>
        </Card>
        <ListDivider text={I18n.t('HISTORY_PAST_LABEL')} page={'history'} showIcon />
        {
          (this.state.healthPrompts &&
            this.state.healthPrompts.history) ?
            <AccordionList data={this.state.healthPrompts.history} />
            :
            <Card
              content={
                <h6>{I18n.t('NO_HEALTH_PROMPTS_MESSAGE')}</h6>
              } />
        }
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const { userToken } = state.auth;
  const { loading, healthPrompts, healthPromptsRaw, laborReportRaw, locationNumber } = state.healthPrompt;
  return { userToken, loading, healthPrompts, healthPromptsRaw, laborReportRaw, locationNumber };
}

export default connect(
  mapStateToProps,
  {
    sendHealthPromptHistoryEmail,
    getHealthPromptLaborReport,
    resetHealthPromptLaborReport
  }
)(HistoryPage);
