import React, { Component } from 'react'
import { DateRange } from 'react-date-range';
import { getLastWeekDate, getTodayDate, isBetween31Days } from '../../../utils';
import './DatePicker.scss';
import { Row } from 'react-bootstrap';
import { PrimaryButton } from '../Buttons/PrimaryButton/PrimaryButton';

class DatePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      startDate: getLastWeekDate(),
      endDate: getTodayDate(),
      disable: false,
      acceptableRange: true
    }

    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDateSelection(item) {
    const { startDate, endDate } = item.range1;
    const acceptableRange = isBetween31Days(startDate, endDate);
    this.setState({ startDate, endDate, acceptableRange });
  }

  handleSubmit() {
    this.setState({disable: true});
    this.props.onSubmit({
      startDate: this.state.startDate,
      endDate: this.state.endDate
    });

  }

  render() {
    return (
      <div className="DatePicker">
        <DateRange
          editableDateInputs={true}
          onChange={this.handleDateSelection}
          moveRangeOnFirstSelection={false}
          ranges={[this.state]}
          rangeColors={["#994878"]}
        />
        {
          !this.state.acceptableRange &&
          <p className="error">Please select a range of 31 days or less</p>
        }
        <Row>
          <PrimaryButton
            disabled={!this.state.acceptableRange || this.state.disable}
            text={this.props.submitText !== undefined ? this.props.submitText : this.state.disable ? "Loading..." : "Submit"}
            onClick={this.handleSubmit}
            className="submit-button"
          />
        </Row>
      </div>
    )
  }
}

export { DatePicker };
