import React, { Component } from 'react';
import './HealthPromptAlert.scss';
import I18n from '../../../i18n/utils';
import { PrimaryButton, SecondaryButton } from '../common';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateHealthPromptAction } from '../../actions';

class HealthPromptAlert extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      promptAlert: props.alert,
      showActionButtons: alert.actions != null,
      idx: props.idx
    }

    this.handleActionButton = this.handleActionButton.bind(this);
    this.toggleActionButtons = this.toggleActionButtons.bind(this);
  }

  componentDidMount() {
  }

  handleActionButton(action) {
    this.props.updateHealthPromptAction(this.state.promptAlert.uuid, action).then(res => {
      let promptAlert = this.state.promptAlert;
      promptAlert['action'] = res;
      this.setState({
        toggleActionButtons: false,
        showActionButtons: false,
        promptAlert
      })
    }).catch(err => console.log(err));
  }

  toggleActionButtons() {
    const showActionButtons = !this.state.showActionButtons;
    this.setState({ showActionButtons });
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    return update;
  }

  render() {
    return (
      <Row className="no-padding">
        <Col
          className="HealthPromptAlert no-padding"
        >
          {
            this.state.idx !== 0 &&
            <hr className="divider" />
          }
          {
            this.state.promptAlert &&
            <>
              <Row className="no-padding alert-details-row">
                <Col xs="7">
                  <Row className="no-padding name-row">
                    <h3>{this.props.alert.name}</h3>
                    {
                      !this.state.promptAlert.at_home &&
                      <img
                        alt="close-icon"
                        src={require('../../assets/icons/verified-user.svg').default}
                        className="verified-icon"
                      />
                    }
                  </Row>
                </Col>
                <Col xs="5">
                  <h5 className="time-stamp">{this.state.promptAlert.time_string}</h5>
                  <h5 className={`location-label ${this.state.promptAlert.at_home ? 'location-label-at-home' : ''}`}>
                    {`${this.state.promptAlert.at_home ? I18n.t('HEALTH_PROMPT_ALERT_AT_HOME_LABEL') : I18n.t('HEALTH_PROMPT_ALERT_POS_LABEL')}`}
                  </h5>
                </Col>
              </Row>
              {
                this.state.promptAlert.action &&
                <Row className="actions-container">
                  <img
                    alt="close-icon"
                    src={require('../../assets/icons/success-circle.svg').default}
                    className="check-icon"
                  />
                  <h6>{I18n.t('HEALTH_PROMPT_ALERT_ACTION_TYPE')} <b>{this.state.promptAlert.action}</b></h6>
                </Row>
              }
              {/* If Action is null, show four button options */}
              <Row>
                {
                  (
                    this.state.promptAlert.action == null ||
                    this.state.showActionButtons
                  ) &&
                  <Container>
                    <Row>
                      <Col>
                        <PrimaryButton
                          disabled={this.state.loading}
                          text={I18n.t('HEALTH_PROMPT_ALERT_ACTION_ERROR')}
                          row
                          outline
                          onClick={() => this.handleActionButton('Error')}
                        />
                      </Col>
                      <Col>
                        <PrimaryButton
                          disabled={this.state.loading}
                          text={I18n.t('HEALTH_PROMPT_ALERT_ACTION_RESTRICTED')}
                          row
                          outline
                          onClick={() => this.handleActionButton('Restricted')}
                        />
                      </Col>
                      <div className="w-100"></div>
                      <Col>
                        <PrimaryButton
                          disabled={this.state.loading}
                          text={I18n.t('HEALTH_PROMPT_ALERT_ACTION_EXCLUDED')}
                          row
                          outline
                          onClick={() => this.handleActionButton('Excluded')}
                        />
                      </Col>
                      <Col>
                        <PrimaryButton
                          disabled={this.state.loading}
                          text={I18n.t('HEALTH_PROMPT_ALERT_ACTION_ACCEPTABLE')}
                          row
                          outline
                          onClick={() => this.handleActionButton('Acceptable')}
                        />
                      </Col>
                    </Row>
                  </Container>
                }
                {
                  this.state.promptAlert.action != null &&
                  <Row className="change-buttons-row">
                    <SecondaryButton text={`${this.state.showActionButtons ? I18n.t('HEALTH_PROMPT_ALERT_CANCEL_BUTTON') : I18n.t('HEALTH_PROMPT_ALERT_CHANGE_BUTTON')}`}
                      onClick={this.toggleActionButtons} />
                  </Row>
                }
              </Row>
            </>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { loading } = state.healthPrompt;
  return { loading };
}

export default connect(
  mapStateToProps,
  {
    updateHealthPromptAction,
  }
)(HealthPromptAlert);