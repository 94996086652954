import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const formatDate = (date) => {
  return dayjs(date).format('MM-D-YY');
}

export const formatReverseDate = (date) => {
  return dayjs(date).format('YYYY-MM-DD');
}

export const formatDateWithFullYear = (date) => {
  return dayjs(date).format('MM-D-YYYY');
}

export const checkSameDay = (date) => {
  return dayjs().isSame(date, 'day');
}

export const formatDateAndTime = (date) => {
  return dayjs(date).format('MM/D/YYYY, h:MM A');
}

export const getTodayDate = () => {
  // Return string format for date-range widget
  return dayjs().startOf('day')['$d'];
}

export const getLastWeekDate = () => {
  // Return string format for date-range widget
  return dayjs().startOf('day').subtract(1, 'week')['$d'];
}

export const isBetween31Days = (startDate, endDate) => {
  const difference = Math.abs(dayjs(startDate).diff(dayjs(endDate), 'day'));
  return difference <= 31 ? true : false;
}