import { showFailureNotification } from '../../utils';
import I18n from '../../../i18n/utils';

export default error => {
  var status;
  
  if ('response' in error && error.response != null) {
    status = error.response.status;
  } else {
    status = error;
  }

  console.log('API Error:', error)

  // TODO: wire up specific actions to respond to specific HTTP status error codes
  switch (status) {
    case 401:
    // Expired or bad access token
      showFailureNotification('You do not have acccess to this page');
      break;
    case 403:
    // Unauthorized
      showFailureNotification(I18n.t('APP_UNAUTHORIZED_ACTION_FAILURE'));
      break;
    // case 500:
    // case 503:
    // case 504:
    default:
      showFailureNotification();
    // show error modal
  }
  return error;
}