import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import history from './app/utils/history';
import ReactNotification from 'react-notifications-component'

import { store } from './app/store';
import { Provider } from 'react-redux';

import Home from './app/pages/Home/Home';

const ENV = process.env.REACT_APP_ENV;

// Disable console logs in prod
if (ENV === 'prod') {
  console.log = () => { };
}

class App extends Component {
  // Google Analytics is automatically invoked in index.html

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <ReactNotification />
          <Route path={'/'} component={Home} exact />
        </Router>
      </Provider>
    );
  }
}

export default App;
