import React, { Component } from 'react';
import { connect } from 'react-redux';
import './FeedbackModal.scss';
import I18n from '../../../../i18n/utils';
import { Modal, Row, Col, InputGroup, Form, FormControl } from 'react-bootstrap';
import { PrimaryButton } from '../../common';
import ReactStars from "react-rating-stars-component";
import Checkbox from 'rc-checkbox';
import { isValidEmail } from '../../../utils';
import { postFeedback, resetFeedbackForm } from '../../../actions';
import CFASpinner from '../../../components/Spinner/CFASpinner';

class FeedbackModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rating: 0,
      feedback: '',
      email: '',
      okToContact: false,
      error: null,
      loading: false,
      message: true
    }

    this.onHide = this.onHide.bind(this);
    this.setRating = this.setRating.bind(this);
    this.toggleOkToContact = this.toggleOkToContact.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.submitDisabled = this.submitDisabled.bind(this);
    this.submit = this.submit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    let update = {};

    // Update Loading
    if (props.loading !== state.loading) {
      update.loading = props.loading;
    }

    // Update Message
    if (props.message !== state.message) {
      update.message = props.message;
    }

    // Update Error
    if (props.error !== state.error) {
      update.error = props.error;
    }

    return update;
  }

  onHide() {
    this.props.onClose(this.state);
    this.props.resetFeedbackForm();
  }

  setRating(starsNum) {
    this.setState({ rating: starsNum });
  }

  toggleOkToContact() {
    const okToContact = !this.state.okToContact;
    this.setState({ okToContact });
  }

  handleTextChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({ ...this.state, [fieldName]: fleldVal });
  }

  submit() {
    let data = {
      ...this.state,
      location_number: this.props.locationNumber,
    }

    this.props.postFeedback(data)
  }

  submitDisabled() {
    let bool = false;
    const { rating, feedback, email, okToContact } = this.state;
    if (
      (rating < 1) ||
      (feedback === '' || null) ||
      (
        okToContact === true &&
        !isValidEmail(email)
      )
    ) {
      bool = true;
    }

    return bool;
  }

  render() {
    return (
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={this.onHide}
        className="FeedbackModal"
      >
        <>

          {!this.state.message ?
            <>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h2>{I18n.t('FEEDBACK_MODAL_TITLE')}</h2>
                  <h5 className="subtitle">{I18n.t('FEEDBACK_MODAL_SUBTITLE')}</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body >
                <Row>
                  <Col>
                    <h3 className="modal-body-header">{I18n.t('FEEDBACK_MODAL_RATE_TITLE')}</h3>
                    <ReactStars
                      count={5}
                      onChange={this.setRating}
                      size={26}
                      color="#cccccc"
                      activeColor="#004f71"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label className="input-label">
                      {I18n.t('FEEDBACK_MODAL_FEEDBACK_LABEL')}
                    </label>
                    <InputGroup size="large" className="feedback-textarea">
                      <FormControl
                        as="textarea"
                        name="feedback"
                        aria-label="With textarea"
                        placeholder={I18n.t('FEEDBACK_MODAL_FEEDBACK_PLACEHOLDER')}
                        onChange={this.handleTextChange}
                      />
                      <label className="input-sublabel">
                        {I18n.t('FEEDBACK_MODAL_DISCLAIMER')}
                      </label>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="checkbox-row">
                  <Checkbox
                    checked={this.state.okToContact}
                    onChange={this.toggleOkToContact}
                    className="checkbox">
                  </Checkbox>
                  <label className="checkbox-label">
                    {I18n.t('FEEDBACK_MODAL_EMAIL_PERMISSION')}
                  </label>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <label className="input-label">
                          {I18n.t('FEEDBACK_MODAL_EMAIL_LABEL')}
                        </label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="email"
                          onChange={this.handleTextChange}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                {
                  this.state.okToContact && !isValidEmail(this.state.email) &&
                  <h5 className="invalid-input">A valid email is required</h5>
                }
              </Modal.Body>
              {
                this.state.loading ?
                  <CFASpinner loading={true} margin={0} />
                  :
                  <Modal.Footer>
                    <PrimaryButton
                      text={I18n.t('FEEDBACK_MODAL_SUBMIT_BUTTON')}
                      onClick={this.submit}
                      className="submit-buton"
                      disabled={this.submitDisabled()}
                    />
                  </Modal.Footer>
              }
            </>
            :
            <Row className="container">
              <Col className="success-container">
                <img
                  alt=""
                  src={require('../../../assets/icons/success-circle.svg').default}
                  className="success-icon"
                />
                <h4>{I18n.t('FEEDBACK_MODAL_SUBMIT_SUCCESS')}</h4>
                <PrimaryButton
                  className="go-back-button"
                  block
                  onClick={this.onHide}
                  text={I18n.t('FEEDBACK_MODAL_CLOSE_BUTTON')}
                />
              </Col>
            </Row>
          }
        </>
      </Modal >
    );
  }
}

const mapStateToProps = state => {
  const { loading, error, message, feedback } = state.healthPrompt;
  return { loading, error, message, feedback };
}

export default connect(
  mapStateToProps,
  {
    postFeedback,
    resetFeedbackForm
  }
)(FeedbackModal);